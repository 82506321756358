<template>
    <!-- 登录路由 -->
    <login-register-box>
        <template v-slot:formBox>
            <!-- 标题 -->
            <div class="login-title">
                <div class="login-title-en">SIGN-IN</div>
                <div class="login-title-cn">登陆</div>
            </div>
            <!-- 注册页导航 -->
            <div class="login-register-nav">
                <span>还没注册？</span>
                <router-link to="/register">立即注册！</router-link>
            </div>
            <!-- 表单 -->
            <div class="login-form-box">
                <el-form
                    label-position="top"
                    :model="form"
                    :rules="rules"
                    ref="loginForm">
                    <!-- 手机号 -->
                    <el-form-item
                        label="手机号 / Phone Number"
                        prop="phone">
                        <el-input
                            v-model="form.phone">
                        </el-input>
                    </el-form-item>
                    <!-- 密码 -->
                    <el-form-item
                        label="密码 / Password"
                        prop="pwd">
                        <el-input
                            v-model="form.pwd"
                            type="password"
                            @keyup.enter.native="handleLogin">
                        </el-input>
                    </el-form-item>
                    <!-- 密码 -->
                    <el-form-item>
                        <div class="login-form-forget">
                            <el-button
                                type="text"
                                @click="handleDialog(true)">
                                忘记密码？
                            </el-button>
                        </div>
                        <el-button
                            type="warning"
                            @click="handleLogin"
                            class="login-form-submit">
                            立即登陆
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
            <!-- 忘记密码 -->
            <el-dialog
                :visible="dialogVisible"
                title="忘记密码"
                width="30%"
                @close="handleDialog(false)"
                :close-on-click-modal="false"
                center>
                <el-form
                    label-position="top"
                    :model="forget_form"
                    :rules="rules"
                    ref="forgetForm">
                    <el-form-item
                        label="手机号 / Phone Number"
                        prop="phone">
                        <el-input
                            v-model="forget_form.phone">
                        </el-input>
                    </el-form-item>
                    <!-- 短信验证 -->
                    <el-form-item
                        label="短信验证 / Message Verification"
                        prop="code">
                        <el-input
                            v-model="forget_form.code"
                            placeholder="短信验证"
                            class="register-form-msg-code">
                            <!-- 获取短信按钮 -->
                            <el-button 
                                slot="suffix" 
                                type="info" 
                                size="mini"
                                @click="handleSendCode"
                                :disabled="msg_btn>=0"
                                plain>
                                <span v-if="msg_btn<0">获取验证码</span>
                                <span v-else>{{msg_btn+"s后重新获取"}}</span>
                            </el-button>
                        </el-input>
                    </el-form-item>
                    <!-- 新密码 -->
                    <el-form-item
                        label="新密码 / Password"
                        prop="pwd">
                        <el-input
                            v-model="forget_form.pwd"
                            type="password"
                            @keyup.enter.native="handleForgetPwd(forget_form)">
                        </el-input>
                    </el-form-item>
                </el-form>
                <template #footer>
                    <div>
                        <el-button
                            type="primary"
                            @click="handleForgetPwd(forget_form)">
                            <span>确定</span>
                        </el-button>
                        <el-button
                            @click="handleDialog(false)">
                            <span>关闭</span>
                        </el-button>
                    </div>
                </template>
            </el-dialog>
        </template>
    </login-register-box>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
    data() {
        // 手机号验证
        let validataPhone = (rule, value, callback) => {
            let reg = /^1[3|5|7|8][0-9]\d{4,8}$/;
            if(value === ""){
                callback(new Error('请输入手机号'));
            }else if(!reg.test(value)){
                callback(new Error('手机号不正确，请重新输入'));
            }else{
                callback();
            }
        };
        return {
            form: {
                phone: "",
                pwd: "",
            },
            rules: {
                phone:[
                    { required: true, validator: validataPhone, trigger: 'blur' },
                ],
                code: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                ],
                pwd: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                ]
            },
            forget_form: {
                phone: "",
                code: "",
                pwd: ""
            },
            // 短信验证码按钮文字
            msg_btn: -1,
        }
    },
    computed: mapState({
        dialogVisible: state => state.dialogVisible
    }),
    components:{
        LoginRegisterBox: () => import("@/components/LoginRegisterBox"),
    },
    methods: {
        ...mapMutations([
            "changeDialogVisible"
        ]),
        ...mapActions([
            "sendCode",
            "postLogin",
            "handleForget"
        ]),
        countTime(val){ // 倒计时函数
            val -= 1;
            this.msg_btn = val;
            if(val >= 0){
                setTimeout(()=>this.countTime(val), 1000);
            }
        },
        handleSendCode(){ // 发送短信验证码
            if(!!this.forget_form.phone){
                this.sendCode(this.forget_form.phone).then((res) => {
                    this.$message({
                        showClose: true,
                        message: res,
                        type: "success"
                    })
                    this.countTime(60);
                }).catch(() => {})
            }else{
                this.$message({
                    showClose: true,
                    message: "您还没有输入手机号，请输入手机号",
                    type: "warning"
                })
            }
        },
        handleDialog(val){
            this.changeDialogVisible(val)
        },
        handleForgetPwd(form){ // 提交忘记密码表单
            this.$refs.forgetForm.validate((valid) => {
                if(!valid){
                    return
                }
                this.handleForget({
                    phone: form.phone,
                    password: form.pwd,
                    smscode: form.code
                }).then((res) => {
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: res.type? "success": "error"
                    })
                    if(res.type) this.handleDialog(false);
                })
            })
        },
        handleLogin(){
            this.$refs.loginForm.validate((valid) => {
                if(!valid){
                    return
                }
                this.postLogin({
                    phone: this.form.phone,
                    password: this.form.pwd
                }).then((res) => {
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: res.type
                    })
                    if(res.type == "success"){
                        setTimeout(() => {
                            this.$router.replace("/main")
                        },1000)
                    }
                }).catch(() => {})
            })
        }
    },
}
</script>

<style scoped>
/* 标题 */
.login-title>div{
    font-size: 30px;
    text-align: center;
}
.login-title-en{
    font-weight: bold;
}
/* 注册页导航 */
.login-register-nav{
    margin-top: 30px;
    margin-bottom: 10px;
}
/* 表单部分 */
.login-form-box{
    margin-bottom: 10px;
    padding: 20px;
    border: 1px solid #EBEEF5;
    border-radius: 10px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
}
/* 表单标题 */
.login-form-box>>>.el-form-item__label{
    margin: 0;
    padding: 0;
    color: #909399;
}
/* 忘记密码 */
.login-form-forget{
    text-align: right;
}
/* 登陆按钮 */
.login-form-submit{
    width: 100%;
}
</style>
